@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
*{
  margin: 0px;
  padding: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
