.accordHeader {
    cursor: pointer;
}
.accordionBody {
    height: 200px;
    overflow-y: scroll;
}
.noData {
    text-align: center;
    padding: 50px;
    font-weight: 600;
    font-size:1rem;
    color: #222;
}

.tableHeading {
    font-weight: 600;
    font-size:1.08rem;
    color: #222;

}