.recentText{
    font-weight: bold;
    color: "#333"; 
    font-size: 2.5rem;
    text-align: center;
    padding: 0 0 1rem;
}
.seemsText{
    font-weight: bold;
    color: "#333"; 
    font-size: 2rem;
    text-align: center;
    padding: 0 0 1rem;
}

.nft_img {
    width: 100%;
    height: auto;
    margin-bottom: 3vh;
    border: 10px solid #ddd;
    border-radius: 4px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.bottomPart {
    margin-top: 1.5rem;
}

.pageContainer{
    margin-bottom: 8rem;
    margin-top: 8rem;
}

.carouselImg {
    width: -webkit-fill-available;
    object-fit: cover;
    height: 60vh;
}