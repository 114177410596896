.accordion-button:focus {
    color: rgb(0, 0, 0);
    background-color: rgb(209, 204, 204);
}

.accordion-button {
    background-color: rgb(238, 233, 233) !important;
}

.accordion-button:hover {
    background-color: rgb(212, 211, 211) !important;
}

.accordion-button:not(.collapsed) {
    background-color: rgb(213, 208, 208) !important;
    color: black !important;
}
