.basicDetails {
    padding: 0.5rem;
}

.basicHeadings {
    padding-bottom: 1.5rem;
}

.nftName {
    font-weight: bolder;
    font-size: 2.5rem;
}

.owner {
    padding-bottom: 0.5rem;
}

.walletConnectSection {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.claimButton {
    background-color: rgb(53, 101, 53);
}

.claimButton:hover {
    background-color: rgb(40, 66, 40);
    border-color: rgba(0, 0, 0, 0.407);
}