.dashboard {
    width: 100vw;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr;
    position: absolute;
}

.adminListContainer {
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    height: 84vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  #adminListHeading {
    padding: 0.5vmax;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.637);
    transition: all 0.5s;
    margin: 2rem;
    margin-top: 5rem;
    text-align: center;
  }
  .form{
    width: 80%;
    margin-left: 15vw;
    padding: 2% 5%;
  }
  .imageBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1475cf;
    height: 20vh;
    max-height: 20vh;
    overflow: hidden;
    width: 30%;
    cursor: pointer;
    border-radius: 5px;
  }
  .imageBoxWithImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    max-height: 20vh;
    overflow: hidden;
    width: 30%;
    cursor: pointer;
    border-radius: 5px;
  }
  .image{
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  
.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

