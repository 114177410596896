.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .logo {
    width: 2.8rem;
    transform: scale(1.6)
  }