.dashboard {
    width: 100vw;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr;
    position: absolute;
}

.adminListContainer {
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    height: 84vh;
    overflow-y: scroll;
  }
  
  #adminListHeading {
    padding: 0.5vmax;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.637);
    transition: all 0.5s;
    margin: 2rem;
    margin-top: 5rem;
    text-align: center;
  }
  
  .actionCell {
    color: rgba(0, 0, 0, 0.527);
    transition: all 0.5s;
  }

  .actionCell:hover{
    color: tomato;
  }
