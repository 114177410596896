.sidebar {
  /* background-color: skyblue; */
  display: flex;
  flex-direction: column;
  padding: 4rem 0rem 4rem 1rem;
  height:85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1.5px solid rgba(0, 0, 0, 0.158);
}


.sidebar a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.493);
  padding: 1rem;
  transition: all 0.5s;
}
.sidebar a:hover {
  color: tomato;
  transform: scale(1.1);
}

.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

/* .sidebar a:last-child {
  
  padding-bottom: 5rem;
} */
/* Hide scrollbars */
::-webkit-scrollbar {
  width: 0em;
  
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
